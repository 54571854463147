<template>
  <v-container id="login" class="fill-height justify-center mt-16" tag="section">
    <div class="d-flex flex-wrap justify-center mb-2 mt-16" style="width: 100%">
      <img src="@/assets/logo/Logo-Verde.png" height="50px" />
    </div>

    <div
      class="d-flex flex-wrap justify-center mb-12"
      style="width: 100%; font-size: 20px; font-weight: 300;"
    >
      <h4 style="font-weight: 400;">
        {{ $t("Genetica") }} · {{ $t("FarmacoGenetica") }}
      </h4>
    </div>

    <v-row justify="center">
      <v-slide-y-transition appear>
        <base-material-card
          color="success"
          light
          max-width="100%"
          width="700"
          class="px-5 py-3"
        >
          <div class="text-center mb-8">
            <h1 class="display-3 font-weight-bold mb-2" style="color: #1C093A;">
              {{ $t("registrate") }}
            </h1>
          </div>
          <validation-observer v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(signup)">
              <v-card-text class="text-center pa-0">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="email"
                >
                  <v-text-field
                    autocomplete="off"
                    rounded
                    outlined
                    v-model="crendentials.email"
                    :label="$t('Direccion email') + '*'"
                    :error-messages="errors"
                    color="secondary"
                  />
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="password"
                >
                  <v-text-field
                    autocomplete="off"
                    rounded
                    outlined
                    v-model="crendentials.password"
                    :label="$t('Contrasena') + '*'"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    :error-messages="errors"
                    color="primary"
                    @click:append.prevent="showPassword = !showPassword"
                  />
                </validation-provider>
                <validation-provider
                  rules="required"
                  v-slot="{ errors }"
                  name="MSC"
                >
                  <v-text-field
                    v-mask="'######AAA'"
                    autocomplete="off"
                    rounded
                    outlined
                    v-model="crendentials.MSC"
                    :label="$t('MSC') + '*'"
                    :error-messages="errors"
                    color="secondary"
                  />
                </validation-provider>
                <validation-provider
                  :rules="{ required: { allowFalse: false } }"
                  v-slot="{ errors }"
                  name="Terms"
                >
                  <v-checkbox v-model="terms" :error-messages="errors">
                    <template v-slot:label>
                      <div>
                        {{ $t("al_registrarte_estas_aceptando nuestros") }}

                        <a
                          target="_blank"
                          href="https://mylogygenomics.com/terminos-y-condiciones/"
                          @click.stop
                          v-on="on"
                        >
                          {{ $t("terminos_y_condiciones_del_sitio_web") }} </a
                        >,
                        {{ $t("la") }}
                        <a
                          target="_blank"
                          href="https://mylogygenomics.com/privacy-policy/"
                          @click.stop
                          v-on="on"
                        >
                          {{ $t("politica_privacidad") }}
                        </a>
                        {{ $t("y_la") }}
                        <a
                          target="_blank"
                          href="https://mylogygenomics.com/privacy-policy/"
                          @click.stop
                          v-on="on"
                        >
                          {{ $t("politica_de_cookies") }} </a
                        >.
                      </div>
                    </template>
                  </v-checkbox>
                </validation-provider>
              </v-card-text>
              <v-card-actions class="pa-0 justify-center mb-4">
                <v-btn
                  default
                  rounded
                  color="primary"
                  type="submit"
                  class="font-weight-bold accent--text mr-0 ml-2"
                >
                  {{ $t("Registrarse") }}
                </v-btn>
              </v-card-actions>
              <v-card-actions class="pa-0 justify-center">
                <a
                  class="mt-1"
                  @click="goToExternal('https://mylogygenomics.com/solicitar-msc/')"
                >
                  {{ $t("no_tengo_msc") }}</a
                >
                <span class="ml-2 mr-2">|</span>{{ $t("ya_tienes_cuenta")
                }}<a class="ml-1" @click="$router.push({ name: 'Login' })">
                  {{ $t("identificate") }}</a
                >
              </v-card-actions>
            </form>
          </validation-observer>
        </base-material-card>
      </v-slide-y-transition>
    </v-row>
    <v-row class="my-16" justify="center">
      <v-col cols="12" sm="6">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Ver política de privacidad
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              De conformidad con lo dispuesto en el Reglamento (UE) 2016/679 relativo a la protección de los datos personales de las personas físicas y con la Ley orgánica 3/2018 de 5 de diciembre, Protección de datos personales y garantía de los derechos digitales, la información personal que nos pueda facilitar quedará debidamente registrada e incorporada a los sistemas de tratamiento de datos responsabilidad de EUROESPES S.A, con la finalidad de tramitar su solicitud. Por tal motivo, si usted no está conforme con el tratamiento descrito, no podremos procesar el objeto de su solicitud. Estos datos personales no serán comunicados a ningún destinatario salvo a aquellos que usted nos autorice o así venga exigido por una ley. Usted podrá ejercer los derechos de acceso, rectificación, supresión, limitación de algún tratamiento específico, portabilidad, decisiones automatizadas y oposición al tratamiento. Para el ejercicio de estos derechos, así como para obtener información adicional sobre el tratamiento de sus datos personales, puede consultar la información adicional en nuestra página web https://mylogygenomics.com/privacy-policy/
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "PagesRegister",
  components: {},
  data: () => ({
    showPassword: false,
    terms: false,
    crendentials: {
      password: "",
      email: "",
      type: "BASIC",
      MSC: null
    }
  }),
  computed: {
    ...mapGetters("app", ["siteURL"])
  },
  created() {
    if (this.$route.query.email) {
      this.crendentials.email = this.$route.query.email;
    }
    if (this.$route.query.MSC) {
      this.crendentials.MSC = this.$route.query.MSC;
    }
  },
  methods: {
    ...mapActions("auth", ["attemptSignup"]),
    goToExternal(url) {
      window.open(url, "_blank");
    },
    signup() {
      let self = this;
      self.$nprogress.start();
      self
        .attemptSignup(this.crendentials)
        .then(response => {
          self.$router.push({ name: "ConfirmationSent" });
        })
        .catch(error => {
          self.$dialog.error({
            text: self.$t(error.message),
            title: self.$t(`Algo salio mal al registrarse`),
            icon: false,
            actions: [self.$t("Cerrar")]
          });
        })
        .then(() => {
          self.$nprogress.done();
        });
    }
  }
};
</script>
